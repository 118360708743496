import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import { db } from '../../firebase';

export const getUserWorkoutExercises = createAsyncThunk(
  'userWorkoutPlan/workoutExercises',
  async ({ workoutPlanId, workoutId, userId }) => {
    //TODO: Get workout exercises
    // plan -> workouts -> workout -> exercises
    const workoutRef = db
      .collection('users')
      .doc(userId)
      .collection('workout_plans')
      .doc(workoutPlanId)
      .collection('workouts')
      .doc(workoutId)
      .collection('exercises')
      .orderBy('index', 'asc');

    const querySnapshot = await workoutRef.get();

    if (querySnapshot.empty) {
      return [];
    }

    const exercises = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }));

    return { workoutId, workoutPlanId, exercises };
  }
);

export const updateUserWorkoutPlan = createAsyncThunk(
  'userWorkoutPlan/update',
  async ({ user, ...payload }) => {
    const workoutPlanRef = db
      .collection('users')
      .doc(user.id)
      .collection('workout_plans')
      .doc(payload.id);
    console.log({ user, ...payload, path: workoutPlanRef.path });
    const batch = db.batch();

    payload.workouts.forEach((workout) => {
      const workoutRef = workoutPlanRef.collection('workouts').doc(workout.id);

      workout.exercises.forEach((exercise) => {
        const exerciseRef = workoutRef.collection('exercises').doc(exercise.id);
        batch.set(exerciseRef, exercise, { merge: true });
      });

      delete workout.exercises;
      batch.set(workoutRef, workout, { merge: true });
    });

    batch.set(
      workoutPlanRef,
      {
        title: payload.title,
        workouts: payload.workouts,
        workoutCount: payload.workouts.length,
        updatedAt: firebase.firestore.Timestamp.now()
      },
      { merge: true }
    );

    // workouts.forEach((workout) => {
    //   batch.set(
    //     db
    //       .collection('users')
    //       .doc(user.id)
    //       .collection('workout_plans')
    //       .doc(workout.id)
    //       .collection('workouts')
    //       .doc(workout.id),
    //     workout
    //   );
    // });
    await batch.commit();

    return { user, ...payload };
    // return { user, ...payload };
  }
);

export const getUserWorkoutplan = createAsyncThunk(
  'userWorkoutPlan/get',
  async ({ workoutPlanId, id, ...rest }) => {
    // const userId = auth.currentUser.uid;
    let currentWorkout = null;

    const workoutRef = db
      .collection('users')
      .doc(id)
      .collection('workout_plans')
      .doc(workoutPlanId);

    //TODO: Get workout exercises
    // plan -> workouts -> workout -> exercises

    const [querySnapshot] = await Promise.all([workoutRef.get()]);

    if (querySnapshot.exists) {
      currentWorkout = querySnapshot.data();
    }

    if (currentWorkout) {
      return {
        ...currentWorkout,
        user: { id, ...rest },
        createdAt: currentWorkout.createdAt.toDate().toISOString(),
        updatedAt: currentWorkout.updatedAt
          ? currentWorkout.updatedAt.toDate().toISOString()
          : ''
      };
    }
    return null;
  }
);

export const slice = createSlice({
  name: 'userWorkoutPlan',
  initialState: {
    data: null,
    isLoading: null,
    error: null
  },
  reducers: {
    setUserWorkoutPlan: (state, { payload }) => {
      state.data = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserWorkoutplan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserWorkoutplan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getUserWorkoutplan.rejected, (state, error) => {
      state.isLoading = false;
    });
    builder.addCase(updateUserWorkoutPlan.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateUserWorkoutPlan.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    });
    builder.addCase(updateUserWorkoutPlan.rejected, (state, error) => {
      console.error(error);
      state.isLoading = false;
    });
    builder.addCase(getUserWorkoutExercises.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserWorkoutExercises.fulfilled, (state, action) => {
      const currentWorkout = state.data.workouts.find(
        (workout) => workout.id === action.payload.workoutId
      );
      currentWorkout.exercises = action.payload.exercises;

      console.log('state', current(state.data));
      console.log('action', action.payload);

      state.isLoading = false;
    });
    builder.addCase(getUserWorkoutExercises.rejected, (state, error) => {
      state.isLoading = false;
    });
  }
});

const { actions, reducer } = slice;

export const { setUserWorkoutPlan } = actions;

export default reducer;
