import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import OnboardingDialog from '../../components/OnboardingDialog/OnboardingDialog';
import { updateUser } from '../../features/users/usersSlice';
import { auth } from '../../firebase';
import { useUser } from '../../hooks/useUser';
import { FALLBACK_URLS } from '../../utils';
import LogoutModal from '../../views/dashboard/LogoutModal';
import NavBar from './NavBar';
import TopBar from './TopBar/TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    display: 'flex',
    minHeight: '100vh'
    // height: '100vh'
  },
  wrapper: {
    display: 'flex',
    height: '100%',
    flex: '1',
    paddingTop: 64,
    backgroundColor: theme.palette.background.lightBlue
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    backgroundColor: theme.palette.background.lightBlue
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'scroll',
    backgroundColor: theme.palette.background.lightBlue
  }
}));

const DashboardLayout = () => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isMobileFilterOpen, setMobileFilterOpen] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [user, isLoading] = useUser();
  const { t } = useTranslation();

  const handleFilterClick = () => {
    setMobileFilterOpen(true);
  };

  const handleClose = () => {
    setMobileFilterOpen(false);
    setMobileNavOpen(false);
  };

  const toggleModal = () => setOpen((prevState) => !prevState);
  const handleSignOut = () => {
    toggleModal();
  };

  const handleUpdateUser = (data) => {
    if (data.firstName && data.lastName) {
      dispatch(
        updateUser({
          id: auth.currentUser.uid,
          name: {
            first: data.firstName,
            last: data.lastName,
            full: `${data.firstName} ${data.lastName}`
          },
          picture: {
            thumbnail: FALLBACK_URLS.USER
          }
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        onMobileFilterOpen={handleFilterClick}
        onSignOut={handleSignOut}
      />
      <NavBar
        onMobileClose={handleClose}
        openMobile={isMobileNavOpen}
        onSignOut={handleSignOut}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
      <LogoutModal open={isOpen} handleClose={toggleModal} />
      <OnboardingDialog
        open={!user?.id && !isLoading}
        emoji="👊"
        title="Get ready for Gymply!"
        onSecondaryAction={handleUpdateUser}
        secondaryButtonText={t('save')}
      />
    </div>
  );
};

export default DashboardLayout;
