import {
  Box,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import PropTypes, { bool, func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';
import ErrorMessage from './ErrorMessage';

const useStyles = makeStyles((theme) => ({
  saveButton: {
    borderRadius: theme.spacing(2) / 1.5,
    border: '2px solid black',
    width: 112,
    height: 48,
    '&:hover': {
      borderWidth: 2
    }
  },
  textStyle: (isButtonDisabled) => ({
    fontSize: 12,
    fontWeight: 700,
    color: isButtonDisabled
      ? theme.palette.action.disabled
      : theme.palette.primary.main
  })
}));

const ModalNavBar = ({
  handleClose,
  onSave,
  navBarRightButtonTitle,
  shouldShowMessage,
  closeMessage,
  isLoading,
  isButtonDisabled
}) => {
  console.log({ isButtonDisabled });
  const classes = useStyles(isButtonDisabled);
  const { t } = useTranslation();

  return (
    <Box component="div" display="flex" justifyContent="space-between">
      {handleClose ? <BackButton onClick={handleClose} /> : <Box />}
      {onSave && (
        <Box>
          <Button
            variant="outlined"
            color="primary"
            className={classes.saveButton}
            onClick={onSave}
            disabled={isButtonDisabled}
            type="submit"
            // disabled
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Typography className={classes.textStyle}>
                {navBarRightButtonTitle !== undefined
                  ? navBarRightButtonTitle
                  : t('save')}
              </Typography>
            )}
          </Button>
          <Box position="absolute" width={112}>
            <ErrorMessage
              successMessage={t('saved')}
              isOpen={shouldShowMessage}
              error={false}
              closeMessage={closeMessage}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ModalNavBar.propTypes = {
  handleClose: PropTypes.func,
  onSave: PropTypes.func,
  closeMessage: func,
  navBarRightButtonTitle: PropTypes.string,
  shouldShowMessage: bool,
  isLoading: bool
};

export default ModalNavBar;
