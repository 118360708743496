import { Container, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CreateUserModal from '../../components/CreateUserModal/CreateUserModal';
import Page from '../../components/Page';
import UsersList from '../../components/UsersList';
import {
  getUserNutritionPlan,
  setNutritionPlan
} from '../../features/user-nutrition-plan/slice';
import {
  getUserWorkoutplan,
  setUserWorkoutPlan
} from '../../features/user-workout-plan/slice';
import { setSelectedUser } from '../../features/users/usersSlice';
import useToggle from '../../hooks/useToggle';
import UserNutritionPlanModal from '../nutrition/UserNutritionPlanModal';
import UserWorkoutPlanModal from './UserWorkoutPlanModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  }
}));

const UsersView = () => {
  const classes = useStyles();
  const [isAddingUser, toggleAddUser] = useToggle(false);
  const { data, isLoading, error } = useSelector(
    (state) => state.userWorkoutPlan
  );

  const { data: nutritionPlan } = useSelector(
    (state) => state.userNutritionPlan
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleListItemClick = ({ user }) => {
    dispatch(setSelectedUser(user));
    navigate(
      `/app/user/${user?.name?.full
        ?.split(' ')
        .join('')
        .toLowerCase()}/profile`,
      {
        state: { user }
      }
    );
  };

  const handleAddUser = () => {
    toggleAddUser();
  };

  const handleEditWorkoutPlan = (user) => {
    dispatch(getUserWorkoutplan(user));
  };

  const handleEditNutritionPlan = (user) => {
    dispatch(getUserNutritionPlan(user));
  };

  const handleCloseModal = () => {
    dispatch(setUserWorkoutPlan(null));
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container>
        <UsersList
          onListItemClick={handleListItemClick}
          onAddUserPress={handleAddUser}
          onEditNutritionPlan={handleEditNutritionPlan}
          onEditWorkoutPlan={handleEditWorkoutPlan}
        />
        {data && (
          <UserWorkoutPlanModal onCloseModal={handleCloseModal} data={data} />
        )}
        {nutritionPlan && (
          <UserNutritionPlanModal
            onCloseModal={() => dispatch(setNutritionPlan(null))}
            data={nutritionPlan}
          />
        )}
        {isAddingUser && (
          <CreateUserModal isOpen={true} handleClose={toggleAddUser} />
        )}
      </Container>
    </Page>
  );
};

export default UsersView;
