import firebase from 'firebase/app';
import 'firebase/auth';
import { useEffect, useState } from 'react';

const useFirebaseSMSAuth = () => {
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [verifier, setVerifier] = useState(null);

  useEffect(() => {
    // Check if container exists and is empty
    const container = document.getElementById('recaptcha-container');
    if (container && !container.hasChildNodes()) {
      const verifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible'
        }
      );
      setVerifier(verifier);
      return () => {
        verifier.clear();
        setVerifier(null);
      };
    }
  }, []);

  const sendVerificationCode = async (phoneNumber) => {
    try {
      // Create new verifier if not exists
      if (!verifier) {
        const newVerifier = new firebase.auth.RecaptchaVerifier(
          'recaptcha-container',
          {
            size: 'invisible'
          }
        );
        setVerifier(newVerifier);
      }

      const provider = new firebase.auth.PhoneAuthProvider();
      const verificationId = await provider.verifyPhoneNumber(
        phoneNumber,
        verifier
      );
      return verificationId;
    } catch (err) {
      setError(err);
      throw err;
    }
  };

  const signInWithVerificationCode = async (
    verificationCode,
    verificationId
  ) => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );
      const userCredential = await firebase
        .auth()
        .signInWithCredential(credential);
      setUser(userCredential.user);
    } catch (err) {
      setError(err);
    }
  };

  const clearError = () => {
    setError(null);
  };

  return {
    sendVerificationCode,
    signInWithVerificationCode,
    error,
    clearError,
    user
  };
};

export default useFirebaseSMSAuth;
