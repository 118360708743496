import { Box, Grid, Hidden, makeStyles } from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSection,
  deleteWorkoutSection
} from '../../features/workoutplans/slice';
import ActionModal from '../ActionModal/ActionModal';
import ModalHeader from '../ModalHeader';
import ModalNavBar from '../ModalNavBar';
import Sections from '../Sections';
import WorkoutForm from '../WorkoutForm';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(5, 5, 0, 5)
  },
  contentContainer: {
    backgroundColor: theme.palette.background.lightBlue
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    paddingBottom: theme.spacing(2)
  },
  subTitle: {
    marginTop: theme.spacing(3)
  },
  textFieldSingle: {
    display: 'block',
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: 52
  },
  textFieldMulti: {
    height: 'auto'
  },
  inputText: {
    fontSize: 14,
    fontWeight: 400,
    width: 700,
    paddingTop: 15
  },
  addOutcomeButton: {
    minWidth: 800
  },
  modal: {}
}));

const AddWorkoutModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const formRef = useRef();
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const dispatch = useDispatch();
  const state = useSelector((state) => state.plans);
  const workout = state.selectedPlan.selectedWorkout;
  const isSaved = state.isSaved;
  const selectedIndex = workout.exercises.findIndex(
    (item) => item.id === workout.selectedExercise.id
  );

  useEffect(() => {
    if (isSaved) {
      onClose();
    }
  }, [isSaved]);

  useEffect(() => {
    if (workout.exercises.length === 0) {
      dispatch(addSection());
    }
  }, [workout.exercises]);

  const renderForm = (item, index) => {
    return <WorkoutForm data={item} index={index} ref={formRef} />;
  };

  const handleSave = () => formRef.current.submit();
  const handleAddSection = () => {
    dispatch(addSection());
  };

  const shouldDeleteLesson = async () => {
    dispatch(deleteWorkoutSection(sectionToDelete));
    setSectionToDelete(null);
  };

  const handleDelete = async (id, index) => {
    setSectionToDelete({ id, index });
  };

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <Box className={classes.wrapper}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box mx={7} mt={3}>
                <ModalNavBar
                  onSave={handleSave}
                  handleClose={onClose}
                  isButtonDisabled={state.isLoading || !workout.isValid}
                />
              </Box>
              <Box my={2} />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={3}></Grid>
              <Grid item xs={7}>
                <ModalHeader title={workout?.title} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Hidden smDown>
                <Sections
                  sections={workout?.exercises}
                  selectedIndex={selectedIndex}
                  onAddSectionClick={handleAddSection}
                  onDelete={handleDelete}
                />
              </Hidden>
            </Grid>

            <Grid
              item
              xs={7}
              container
              justifyContent="center"
              style={{
                maxHeight: 700,
                overflow: 'scroll',
                paddingBottom: 100
              }}
            >
              <Grid item xs={12}>
                {workout?.exercises?.map((item, index) => (
                  <Box
                    key={item.id}
                    hidden={item.id !== workout?.selectedExercise?.id}
                    pr={3}
                  >
                    {renderForm(item, index)}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ActionModal
        open={sectionToDelete}
        emoji="🗑"
        title={t('delete exercise')}
        description={t('deleteExcDesc')}
        primaryButtonText={t('delete')}
        onPrimaryAction={shouldDeleteLesson}
        onSecondaryAction={() => setSectionToDelete(null)}
      />
    </>
  );
};

AddWorkoutModal.propTypes = {
  shouldEdit: bool,
  introText: PropTypes.string,
  courseID: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onSave: PropTypes.func,
  onAddSectionClick: PropTypes.func,
  sections: array,
  onDelete: func,
  handleSelectSection: func,
  selectedSection: object,
  setSections: func,
  sectionsToAdd: func,
  sectionsToDelete: func,
  onClickBackButton: func,
  saveLessonDialogOpen: bool,
  closeSaveLessonDialog: func,
  setShouldEditLesson: func
};

export default AddWorkoutModal;
