import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import React, { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActionModal from '../../components/ActionModal/ActionModal';
import BackButton from '../../components/BackButton';
import WorkoutsList from '../../components/DragAndDropList/DragAndDropList';
import PlanModal from '../../components/PlanModal/PlanModal';
import WorkoutModal from '../../components/WorkoutModal/WorkoutModal';
import {
  getUserWorkoutExercises,
  updateUserWorkoutPlan
} from '../../features/user-workout-plan/slice';
import { deletePlan } from '../../features/workoutplans/slice';
import useToggle from '../../hooks/useToggle';
import Header from '../eventdetail/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}));

const UserWorkoutPlanModal = ({ data: plan, onCloseModal }) => {
  console.log('plan', plan);
  const classes = useStyles();
  const [data, setData] = useState(plan);
  const event = {
    media: data.media,
    title: data.title,
    description: data.description,
    workoutCount: data.workoutCount
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [workoutToDelete, setWorkoutToDelete] = useState(null);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [isDeleting, toggleIsDeleting] = useToggle(false);
  const [isAddingUser, toggleAddUser] = useToggle(false);
  const { t } = useTranslation();
  const [isEditing, toggleIsEditing] = useToggle(false);

  console.log({ selectedWorkout });

  const handleClose = () => {
    setSelectedWorkout(null);
  };

  const handleListItemClick = async ({ id: workoutId, title }) => {
    if (workoutId && data?.user?.id) {
      const workout = data.workouts.find((item) => item.id === workoutId);
      const result = await dispatch(
        getUserWorkoutExercises({
          workoutPlanId: data.id,
          workoutId,
          userId: data.user.id
        })
      ).unwrap();
      setSelectedWorkout({ ...workout, exercises: result.exercises });
    }
  };

  const handleDeleteWorkout = (workoutId) => {
    setWorkoutToDelete(workoutId);
  };

  const shouldDeleteWorkout = () => {
    // dispatch(deleteWorkoutById({ planId: id, workoutId: workoutToDelete }));
    onWorkoutDeleted();
  };

  const onWorkoutDeleted = () => setWorkoutToDelete(null);

  const handleDeletePlan = async () => {
    try {
      await dispatch(deletePlan()).unwrap();
      navigate('..');
    } catch (error) {}
  };

  const handleUpdateUserWorkoutPlan = async (dataToUpdate) => {
    const updatedData = {
      ...data,
      workouts: data.workouts.map((item) =>
        item.id === dataToUpdate.id ? dataToUpdate : item
      )
    };

    delete updatedData.createdAt;
    console.log({ updatedData });
    handleClose();
    try {
      await dispatch(updateUserWorkoutPlan(updatedData)).unwrap();
    } catch (error) {
      console.log(error);
    }
    setData(updatedData);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        <Container>
          <Box display="flex" alignItems="center">
            <BackButton onClick={onCloseModal} />
            <Box ml={1}>
              <Typography variant="h4">
                {plan?.user?.name?.full ?? ''}
              </Typography>
            </Box>
          </Box>
          <Suspense fallback="loading">
            <Header
              data={event}
              // onEditEvent={handleEditEvent}
              // onDelete={toggleIsDeleting}
            />
            <Box mt={2}>
              <WorkoutsList
                onListItemClick={handleListItemClick}
                onDelete={handleDeleteWorkout}
                data={data.workouts}
                canAddWorkouts={false}
              />
            </Box>
          </Suspense>
          {selectedWorkout && (
            <WorkoutModal
              isOpen={true}
              onSave={handleUpdateUserWorkoutPlan}
              onClose={handleClose}
              workout={selectedWorkout}
            />
          )}
        </Container>
        <ActionModal
          open={workoutToDelete}
          onSecondaryAction={onWorkoutDeleted}
          onPrimaryAction={shouldDeleteWorkout}
          title={t('deleteWorkout')}
          description={t('deleteWorkoutDesc')}
          primaryButtonText={t('delete')}
          emoji="🗑"
        />
        <ActionModal
          open={isDeleting}
          onSecondaryAction={() => toggleIsDeleting()}
          onPrimaryAction={handleDeletePlan}
          title={t('deletePlan')}
          description={t('deletePlanDesc')}
          primaryButtonText={t('delete')}
          emoji="🗑"
        />
        {isEditing && (
          <PlanModal
            isOpen={true}
            handleClose={toggleIsEditing}
            modalTitle={t('edit plan')}
            defaultValues={plan}
          />
        )}
      </div>
    </Modal>
  );
};

export default UserWorkoutPlanModal;
