import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import exercisesReducer from '../features/exercises/exercises';
import filterReducer from '../features/filter/filterSlice';
import nutritionReducer from '../features/nutrition-plans/slice';
import userNutritionPlanReducser from '../features/user-nutrition-plan/slice';
import userWorkoutPlanReducer from '../features/user-workout-plan/slice';
import usersReducer from '../features/users/usersSlice';
import plansReducer from '../features/workoutplans/slice';

const store = configureStore({
  reducer: {
    filter: filterReducer,
    users: usersReducer,
    plans: plansReducer,
    dashboard: dashboardReducer,
    exercises: exercisesReducer,
    nutrition: nutritionReducer,
    userWorkoutPlan: userWorkoutPlanReducer,
    userNutritionPlan: userNutritionPlanReducser
  },
  devTools: true
});

export default store;
