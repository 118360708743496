import { Box, Grid, Hidden, makeStyles } from '@material-ui/core/';
import Modal from '@material-ui/core/Modal';
import { isEqual } from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes, { array, bool, func, object } from 'prop-types';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionModal from '../ActionModal/ActionModal';
import ModalHeader from '../ModalHeader';
import ModalNavBar from '../ModalNavBar';
import Sections from '../Sections';
import WorkoutForm from './WorkoutForm';

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    padding: theme.spacing(5, 5, 0, 5)
  },
  contentContainer: {
    backgroundColor: theme.palette.background.lightBlue
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    paddingBottom: theme.spacing(2)
  },
  subTitle: {
    marginTop: theme.spacing(3)
  },
  textFieldSingle: {
    display: 'block',
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: theme.palette.common.white,
    width: '100%',
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: 52
  },
  textFieldMulti: {
    height: 'auto'
  },
  inputText: {
    fontSize: 14,
    fontWeight: 400,
    width: 700,
    paddingTop: 15
  },
  addOutcomeButton: {
    minWidth: 800
  },
  modal: {}
}));

const WorkoutModal = ({
  isOpen,
  onClose,
  title,
  setTitle,
  workout,
  onSave
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [data, setData] = useState(workout);
  const [selectedSection, setSelectedSection] = useState(
    data?.exercises?.[0] ?? []
  );
  const [sectionToDelete, setSectionToDelete] = useState(null);

  const selectedIndex = data?.exercises?.findIndex(
    (item) => item.id === selectedSection.id
  );

  const handleSetWorkoutValid = useCallback(({ id, isValid }) => {
    setData((prevState) => {
      const exercise = prevState.exercises.find((item) => item.id === id);

      // Only update if the validity status has changed
      if (exercise?.isValid === isValid) {
        return prevState;
      }

      return {
        ...prevState,
        exercises: prevState.exercises.map((item) =>
          item.id === id ? { ...item, isValid } : item
        )
      };
    });
  }, []);

  const handleFormChange = useCallback((value) => {
    console.log({ value });
    setData((prevState) => {
      const isWorkoutTitle = value.id === prevState.exercises[0].id;
      const needsTitleUpdate =
        isWorkoutTitle && value.title !== prevState.title;
      const exerciseIndex = prevState.exercises.findIndex(
        (ex) => ex.id === value.id
      );
      const needsExerciseUpdate = !isEqual(
        prevState.exercises[exerciseIndex],
        value
      );

      if (!needsTitleUpdate && !needsExerciseUpdate) {
        return prevState;
      }

      return {
        ...prevState,
        title: isWorkoutTitle ? value.title : prevState.title,
        exercises: prevState.exercises.map((item) =>
          item.id === value.id ? value : item
        )
      };
    });
  }, []);

  const renderForm = useCallback(
    (item, index) => {
      return (
        <WorkoutForm
          key={item.id}
          data={item}
          index={index}
          onFormChange={handleFormChange}
          setFormValid={handleSetWorkoutValid}
        />
      );
    },
    [handleFormChange, handleSetWorkoutValid]
  );

  const handleSave = () => {
    onSave(data);
  };
  const handleAddExercise = () => {
    const newExc = {
      id: nanoid(),
      title: '',
      index: data.exercises.length + 1
    };

    setData((prevState) => ({
      ...prevState,
      exercises: [...prevState.exercises, newExc]
    }));

    setSelectedSection(newExc);
    // dispatch(addSection());
  };

  const shouldDeleteExercise = async () => {
    setData((prevState) => ({
      ...prevState,
      exercises: prevState.exercises.filter(
        (item) => item.id !== sectionToDelete.id
      )
    }));

    if (sectionToDelete.index > 1) {
      setSelectedSection(data.exercises[sectionToDelete.index - 1]);
    }

    setSectionToDelete(null);
  };

  const handleDelete = async (id, index) => {
    setSectionToDelete({ id, index });
  };

  const isFormValid = data.exercises.every((item) => item.isValid);
  console.log({ isFormValid, exc: data.exercises });

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.modal}
      >
        <Box className={classes.wrapper}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box mx={7} mt={3}>
                <ModalNavBar
                  onSave={handleSave}
                  handleClose={onClose}
                  isButtonDisabled={!isFormValid}
                />
              </Box>
              <Box my={2} />
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={3}></Grid>
              <Grid item xs={7}>
                <ModalHeader title={data?.title} />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              <Hidden smDown>
                <Sections
                  sections={data?.exercises}
                  selectedSection={selectedSection}
                  onSectionItemClick={setSelectedSection}
                  onAddSectionClick={handleAddExercise}
                  onDelete={handleDelete}
                  selectedIndex={selectedIndex}
                />
              </Hidden>
            </Grid>

            <Grid
              item
              xs={7}
              container
              style={{
                maxHeight: 700,
                overflow: 'scroll',
                paddingBottom: 100
              }}
            >
              <Grid item xs={12}>
                {data?.exercises?.map((item, index) => (
                  <Box
                    key={item.id}
                    hidden={item.id !== selectedSection.id}
                    pr={3}
                  >
                    {renderForm(item, index)}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ActionModal
        open={sectionToDelete}
        emoji="🗑"
        title={t('delete exercise')}
        description={t('deleteExcDesc')}
        primaryButtonText={t('delete')}
        onPrimaryAction={shouldDeleteExercise}
        onSecondaryAction={() => setSectionToDelete(null)}
      />
    </>
  );
};

WorkoutModal.propTypes = {
  shouldEdit: bool,
  introText: PropTypes.string,
  courseID: PropTypes.string,
  isOpen: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onSave: PropTypes.func,
  onAddSectionClick: PropTypes.func,
  sections: array,
  onDelete: func,
  handleSelectSection: func,
  selectedSection: object,
  setSections: func,
  sectionsToAdd: func,
  sectionsToDelete: func,
  onClickBackButton: func,
  saveLessonDialogOpen: bool,
  closeSaveLessonDialog: func,
  setShouldEditLesson: func
};

export default WorkoutModal;
