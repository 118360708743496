import { Box } from '@material-ui/core/';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import UploadImage from '../../UploadImage';
import { useForm } from 'react-hook-form';
import Modal from '../Modal';
import MultilineTextfield from '../MultilineTextfield';
import TextFieldSection from '../TextFieldSection';

const Column = ({ children }) => <Box mt={2}>{children}</Box>;

const AddEventModal = ({
  isOpen,
  handleClose,
  handleSave,
  isEditing,
  isLoading,
  state,
  modalTitle,
  defaultValues,
  onChange
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    control,
    watch,
    trigger,
    formState: { isValid, isDirty, errors }
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues || {
      title: '',
      description: ''
      // imageUrl: ''
    }
  });

  useEffect(() => {
    trigger();
    const subscription = watch(onChange);
    return () => subscription.unsubscribe();
  }, [watch, trigger]);

  const { t } = useTranslation();

  const onSave = () => {
    handleSave({ file: selectedFile });
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={onSave}
      title={modalTitle ?? t('create event')}
      isLoading={isLoading}
      disabled={!isValid}
    >
      {/* <TextFieldTitle
            title={t('image')}
            isValid={selectedFile || defaultValues?.media}
          />
          <Box ml={3} mt={1}>
            <ImagePicker
              defaultValue={defaultValues?.media}
              setSelectedFile={setSelectedFile}
            />
          </Box> */}
      <Column>
        <TextFieldSection
          title={t('title')}
          placeholder={t('type a title')}
          isValid={!errors.title}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('error.required')
            }
          }}
          name="title"
          defaultValue={defaultValues?.title}
        />
      </Column>
      <Column>
        <Box mt={1}>
          <MultilineTextfield
            id="description"
            control={control}
            title={t('description')}
            placeholder={t('description here')}
            defaultValue={defaultValues?.description}
            isValid={!errors.description}
            name="description"
            rules={{
              required: {
                value: false,
                message: t('error.required')
              }
            }}
          />
        </Box>
      </Column>
    </Modal>
  );
};

AddEventModal.propTypes = {
  introText: PropTypes.string,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
  children: PropTypes.instanceOf(Element),
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
  onSave: PropTypes.func,
  state: PropTypes.object
};

export default AddEventModal;
