import { Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Image from 'material-ui-image';
import { bool, func, number, object } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Description from './Description';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    paddingLeft: 0,
    boxShadow: 'none',
    backgroundColor: 'inherit'
  },
  image: {
    width: 363,
    height: 184
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: 363,
    height: 184,
    borderRadius: 8,
    objectFit: 'cover'
  },
  actions: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2)
  },
  publish: {
    fontSize: 12,
    fontWeight: '700',
    color: theme.palette.common.white
  },
  unpublish: {
    fontSize: 12,
    fontWeight: '700',
    color: theme.palette.primary.main
  }
}));

const Header = ({ data, onEditEvent, onDelete, counterText }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { media, title, description, workoutCount } = data || {
    description: '',
    totalLessons: 0,
    isPublished: false,
    totalLearners: 0,
    ratedBy: [],
    totalTeams: 0,
    media: '',
    title: '',
    description: '',
    workoutCount: 0
  };

  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Box display="flex" flex={1} height={225}>
          <Box display="flex" mr={4} justifyContent="center">
            {media && (
              <Image
                aspectRatio={363 / 184}
                src={media}
                style={{
                  borderRadius: 12,
                  maxHeight: 225,
                  width: 363,
                  objectFit: 'cover'
                }}
                imageStyle={{
                  borderRadius: 12
                }}
              />
            )}
          </Box>
          <Box display="flex" flex={1} flexDirection="column">
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              maxHeight={225}
              overflow="auto"
              mb={1}
              pr={2}
            >
              <Typography variant="h1">{title ?? ''}</Typography>
              <Description text={description ?? ''} />
            </Box>
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div style={{ display: 'flex', minWidth: 332 }}>
                  {onEditEvent && (
                    <PrimaryButton onClick={onEditEvent}>
                      {t('edit')}
                    </PrimaryButton>
                  )}
                  <Box mx={0.5} />
                  {onDelete && (
                    <PrimaryButton secondary onClick={onDelete}>
                      {t('delete')}
                    </PrimaryButton>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <div>
                    <Typography display="inline" variant="subtitle1">
                      {counterText ??
                        `${workoutCount ?? 0} ${t('workout', {
                          count: workoutCount ?? 0
                        }).toLowerCase()}`}
                    </Typography>
                  </div>
                </div>
              </div>
              {/* <Divider className={classes.divider} /> */}
            </div>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

Header.propTypes = {
  data: object,
  handlePublish: func,
  handleUnpublish: func,
  onEditEvent: func,
  tabIndex: number,
  canBePublished: bool
};

export default Header;
