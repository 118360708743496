import { makeStyles } from '@material-ui/core';
import React from 'react';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    display: 'flex',
    height: '100vh',
    overflow: 'hidden'
  },
  wrapper: {
    display: 'flex',
    flex: '1' // Makes this div grow to fill available space in the flex container
  }
}));

const MainLayout = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
