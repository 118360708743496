import { Box, Grid } from '@material-ui/core/';
import { isEqual } from 'lodash';
import { func, object } from 'prop-types';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSelectedWorkoutUrl } from '../../features/workoutplans/slice';
import useExercises from '../../firestore/useExercises';
import MultilineTextfield from '../MultilineTextfield';
import TextFieldSection from '../TextFieldSection';
import VideoPlayer from '../VideoPlayer';

//TODO: User Workout Modal needs a separate Form
const WorkoutForm = forwardRef(
  ({ data, index, setFormValid, onFormChange }, ref) => {
    const { t } = useTranslation();
    const { exercises } = useExercises();
    const {
      handleSubmit,
      control,
      watch,
      trigger,
      setError,
      setValue,
      clearErrors,
      getValues,
      resetField,
      formState
    } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      defaultValues: data ?? {
        title: '',
        link: '',
        description: '',
        rest: ''
      }
    });

    const { errors, isValid } = formState;

    // const [url, setUrl] = useState(null);
    const dispatch = useDispatch();
    const state = useSelector((state) => state.userWorkoutPlan);
    const allValues = watch();
    const url = useWatch({
      control,
      name: 'url'
    });

    const plan = state.data;

    useEffect(() => {
      trigger();
    }, []);

    // Replace the commented out useEffect with this one
    useEffect(() => {
      // Only notify parent if validation state has changed
      if (data.isValid !== isValid) {
        setFormValid({ isValid, id: data.id });
      }
    }, [isValid, data.id, data.isValid, setFormValid]);

    const handleVideoPlayerStateChange = (error, isReady) => {
      if (error) {
        setError('url', { type: 'value', message: 'Invalid video link' });
      }

      if (isReady) {
        clearErrors('url');
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: handleSubmit(onSubmit)
      }),
      [watch]
    );

    const handleDeleteVideoUrl = () => {
      resetField('url');
      handleVideoPlayerStateChange(true, false);
      dispatch(
        deleteSelectedWorkoutUrl({
          url: ''
        })
      );
    };

    // Add debounced form change handler
    useEffect(() => {
      const subscription = watch((value) => {
        // Only call onFormChange if we have actual changes
        if (!isEqual(value, data)) {
          onFormChange({ ...value, id: data.id });
        }
      });

      return () => subscription.unsubscribe();
    }, [watch, onFormChange, data, formState]);

    return (
      <form>
        <TextFieldSection
          title={index === 0 ? t('workout name') : t('exercise name')}
          placeholder={
            index === 0
              ? t('workout name placeholder')
              : t('exercise name placeholder')
          }
          autocomplete={index !== 0}
          autocompleteData={exercises}
          isValid={!errors.title}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('error.required')
            }
          }}
          name="title"
          defaultValue={data?.title}
          setValue={setValue}
        />
        {index !== 0 && (
          <Grid container>
            <Grid item xs>
              <TextFieldSection
                title={t('sets')}
                placeholder={t('add set')}
                control={control}
                name="sets"
                type="number"
                isValid={!errors.sets}
                defaultValue={data?.sets}
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  min: {
                    value: 1,
                    message: t('error.minValue')
                  },
                  max: {
                    value: 99,
                    message: t('error.maxValue')
                  }
                }}
              />
            </Grid>
            <Grid item xs>
              <TextFieldSection
                title={t('add min reps')}
                placeholder={t('add min reps')}
                isValid={!errors.minReps}
                control={control}
                name="minReps"
                type="number"
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  min: {
                    value: 1,
                    message: t('error.minValue')
                  },
                  max: {
                    value: 99,
                    message: t('error.maxValue')
                  }
                }}
                defaultValue={data?.minReps}
              />
            </Grid>
            <Grid item xs>
              <TextFieldSection
                title={t('add max reps')}
                placeholder={t('add max reps')}
                control={control}
                isValid={!errors.maxReps}
                name="maxReps"
                type="number"
                defaultValue={data?.maxReps}
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  min: {
                    value: 1,
                    message: t('error.minValue')
                  },
                  max: {
                    value: 99,
                    message: t('error.maxValue')
                  }
                }}
              />
            </Grid>
            <Grid item xs>
              <TextFieldSection
                title={t('rest')}
                placeholder={t('add rest time')}
                control={control}
                isValid={!errors.rest}
                name="rest"
                type="number"
                defaultValue={data?.rest}
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  min: {
                    value: 0,
                    message: t('error.minValue')
                  },
                  max: {
                    value: 600,
                    message: t('error.maxValue')
                  }
                }}
              />
            </Grid>
          </Grid>
        )}
        <Box mt={3} />
        <MultilineTextfield
          id="description"
          control={control}
          title={t('description')}
          placeholder={t('description here')}
          defaultValue={data?.description}
          isValid={!errors.description}
          name="description"
          rules={{ required: false }}
        />
        <Box m={2} />
        <TextFieldSection
          control={control}
          id="videoLinkInput"
          title={t('insert youtube link')}
          placeholder={t('paste video link')}
          name="url"
          rules={{ required: false }}
          isValid={!errors.url}
          defaultValue={data?.url}
          value={data?.url}
          onEndAdornmentPress={getValues('url') ? handleDeleteVideoUrl : null}
        />
        {(url || data?.url) && (
          <Box mx={0.5} mb={3}>
            <VideoPlayer
              url={url || data?.url}
              onVideoPlayerStateChanged={handleVideoPlayerStateChange}
            />
          </Box>
        )}
      </form>
    );
  }
);

WorkoutForm.propTypes = {
  onStateChange: func,
  state: object
};

export default WorkoutForm;
