import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { default as LockOutlinedIcon } from '@material-ui/icons/LockOutlined';
import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import TextFieldSection from '../../components/TextFieldSection.js';
import app from '../../firebase.js';

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://material-ui.com/"> */}
      {'Gymply '}
      {/* </Link>{" "} */}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
};
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const OnboardingView = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors, isValid },
    trigger
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    trigger();
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      const { firstName, lastName, email, password } = data;
      try {
        await app.auth().createUserWithEmailAndPassword(email, password);
        await app.auth().currentUser.updateProfile({
          displayName: `${firstName} ${lastName}`
        });
        navigate('/app/workouts');
      } catch (error) {
        alert(error);
      }
    },
    [navigate]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          gymply
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.form}
          noValidate
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextFieldSection
                isValid={!errors?.firstName}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                placeholder={t('firstName')}
                title={t('firstName')}
                autoFocus
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldSection
                isValid={!errors?.lastName}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                title={t('lastName')}
                placeholder={t('lastName')}
                name="lastName"
                autoComplete="lname"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldSection
                isValid={!errors?.email}
                id="email"
                title={t('email')}
                placeholder={t('email')}
                name="email"
                control={control}
                autoComplete="email"
                autoFocus
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t('error.invalidEmail')
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldSection
                rules={{
                  required: {
                    value: true,
                    message: t('error.required')
                  },
                  minLength: {
                    value: 6,
                    message: t('error.minLength', { count: 6 })
                  }
                }}
                isValid={!errors?.password}
                fullWidth
                name="password"
                placeholder={t('password')}
                title={t('password')}
                control={control}
                type="password"
                id="password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isValid}
          >
            {t('signUp')}
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                {/* Already have an account? Sign in */}
                {t('accountExists')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default OnboardingView;
