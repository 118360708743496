import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { nanoid } from 'nanoid';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ActionModal from '../../components/ActionModal/ActionModal';
import AddUserModal from '../../components/AddUserModal/AddUserModal';
import BackButton from '../../components/BackButton';
import DragAndDropList from '../../components/DragAndDropList/DragAndDropList';
import MealModal from '../../components/MealModal/MealModal';
import PlanModal from '../../components/PlanModal/PlanModal';
import { addUserNutritionPlan } from '../../features/nutrition-plans/slice';
import { updateUserNutritionPlan } from '../../features/user-nutrition-plan/slice';
import useToggle from '../../hooks/useToggle';
import Header from '../eventdetail/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.lightBlue,
    minHeight: '100%',
    paddingTop: theme.spacing(3)
  },
  wrapper: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.lightBlue,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}));

const UserNutritionPlanModal = ({ data, onCloseModal }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [nutritionPlan, setNutritionPlan] = useState(data);
  const [mealToDelete, setMealToDelete] = useState(null);
  const [isDeleting, toggleIsDeleting] = useToggle(false);
  const [isAddingUser, toggleAddUser] = useToggle(false);
  const { t } = useTranslation();
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [isAddingMeal, toggleAddingMeal] = useToggle(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (selectedItem) {
      handleClickOpen();
    }
  }, [selectedItem]);

  useEffect(() => {
    return () => onCloseModal();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (selectedItem) {
      setSelectedItem(null);
    } else {
      toggleAddingMeal();
    }
  };

  const handleListItemClick = ({ id, title }) => {
    if (id) {
      // set selected meal
      setSelectedItem(nutritionPlan.meals.find((item) => item.id === id));
    } else {
      toggleAddingMeal();
    }
  };

  const handleDeleteMealClick = (mealId) => {
    setMealToDelete(mealId);
  };

  // const shouldDeleteMeal = async () => {
  //   const payload = {
  //     planId: id,
  //     mealId: mealToDelete,
  //     meals: nutritionPlan?.meals.filter((item) => item.id !== mealToDelete)
  //   };
  //   await dispatch(deleteMeal(payload));
  //   onMealDeleted();
  // };

  const onMealDeleted = () => setMealToDelete(null);

  const handleDeletePlan = async () => {
    try {
      // await dispatch(deleteNutritionPlan());
      navigate('..');
      // handleClose();
    } catch (error) {}
  };

  const shouldAddUser = async ({ user }) => {
    try {
      dispatch(addUserNutritionPlan({ user, nutritionPlan }));
    } catch (error) {
    } finally {
      toggleAddUser();
    }
  };

  const update = (updatedPlan) => {
    dispatch(updateUserNutritionPlan(updatedPlan));
    setNutritionPlan(updatedPlan);
    onMealDeleted();
  };

  const shouldEditMeal = async (dataToEdit) => {
    const normalizedData = dataToEdit.map(({ isValid, ...rest }) => ({
      ...rest
    }));

    const meal = normalizedData.shift();
    meal.ingredients = normalizedData;

    const updatedPlan = {
      ...nutritionPlan,
      meals: nutritionPlan.meals.map((item) =>
        item.id === meal.id ? meal : item
      )
    };
    update(updatedPlan);
    handleClose();
  };

  const shouldAddMeal = async (dataToAdd) => {
    const normalizedData = dataToAdd.map(({ id, isValid, ...rest }) => ({
      ...rest
    }));
    const meal = normalizedData.shift();
    meal.id = nanoid();
    meal.ingredients = normalizedData;

    const updatedPlan = {
      ...nutritionPlan,
      meals: [...nutritionPlan.meals, meal]
    };

    update(updatedPlan);
    handleClose();
  };

  const handleDeleteMeal = () => {
    const updatedPlan = {
      ...nutritionPlan,
      meals: nutritionPlan.meals.filter((item) => item.id !== mealToDelete)
    };
    update(updatedPlan);
  };

  return (
    <Modal
      open={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.wrapper}>
        <Container>
          <Box display="flex" alignItems="center">
            <BackButton onClick={onCloseModal} />
            <Box ml={1}>
              <Typography variant="h4">
                {nutritionPlan?.user?.name?.full ?? ''}
              </Typography>
            </Box>
          </Box>
          <Suspense fallback="loading">
            <Header
              data={nutritionPlan}
              counterText={t('mealsCount', {
                count: nutritionPlan?.meals?.length ?? 0
              })}
            />
            <Box mt={2}>
              <DragAndDropList
                // onAddWorkout={handleListItemClick}
                // onAddUser={handleAddUser}
                // onDelete={handleDeleteMeal}
                onDelete={handleDeleteMealClick}
                data={nutritionPlan?.meals}
                primaryButtonTitle={t('addMeal')}
                onListItemClick={handleListItemClick}
              />
            </Box>
          </Suspense>
          {isAddingMeal && (
            <MealModal
              isOpen={true}
              onClose={handleClose}
              onSave={shouldAddMeal}
            />
          )}
          {selectedItem && (
            <MealModal
              isOpen={true}
              onClose={handleClose}
              defaultValues={[
                {
                  id: selectedItem.id,
                  title: selectedItem.title,
                  description: selectedItem.description
                },
                ...(selectedItem?.ingredients ? selectedItem.ingredients : [])
              ]}
              onSave={shouldEditMeal}
            />
          )}
        </Container>
        {mealToDelete && (
          <ActionModal
            open
            onSecondaryAction={onMealDeleted}
            onPrimaryAction={handleDeleteMeal}
            title={t('deleteMeal')}
            description={t('deleteMealDesc')}
            primaryButtonText={t('delete')}
            emoji="🗑"
          />
        )}
        <ActionModal
          open={isDeleting}
          onSecondaryAction={() => toggleIsDeleting()}
          onPrimaryAction={handleDeletePlan}
          title={t('deleteNutritionPlan')}
          description={t('deleteNutritionDesc')}
          primaryButtonText={t('delete')}
          emoji="🗑"
        />
        {isEditing && (
          <PlanModal
            isOpen={true}
            handleClose={toggleIsEditing}
            modalTitle={t('edit plan')}
          />
        )}
        {isAddingUser && (
          <AddUserModal
            isOpen={true}
            handleClose={toggleAddUser}
            onAddUser={shouldAddUser}
          />
        )}
      </div>
    </Modal>
  );
};

export default UserNutritionPlanModal;
